
import * as ipxRuntime$Lu84DqiL6g from '/usr/local/app/node_modules/.pnpm/@nuxt+image@1.8.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "dev.exalted.store",
    "staging.exalted.store",
    "exalted.store",
    "exalted.com",
    "fra1.digitaloceanspaces.com",
    "nyc3.digitaloceanspaces.com",
    "ams3.digitaloceanspaces.com",
    "sfo2.digitaloceanspaces.com",
    "sfo3.digitaloceanspaces.com",
    "sgp1.digitaloceanspaces.com",
    "lon1.digitaloceanspaces.com",
    "blr1.digitaloceanspaces.com",
    "syd1.digitaloceanspaces.com"
  ],
  "alias": {},
  "densities": [
    1,
    2,
    1,
    2
  ],
  "format": [
    "avif",
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$Lu84DqiL6g, defaults: {} }
}
        