import type { AddressFragment, ChannelFragment, CollectionDetailFragment, CollectionListItemFragment, CountryFragment, CustomerFragment, FacetValueFragment, OrderFragment, PaymentMethodQuoteFragment, ProductFragment, SearchResultFragment, ShippingMethodQuoteFragment } from '#graphql-operations'

export interface SearchState {
  [key: string]: {
    pages?: Record<string, string[]>
    search?: {
      totalItems: number
      facetValues: {
        count: number
        facetValue: FacetValueFragment
      }[]
    }
    searchFacetValues?: {
      totalItems: number
      facetValues?: {
        count: number
        facetValue: FacetValueFragment
      }[]
    }
  } | undefined
}

export interface ActiveCustomerOrdersState {
  [key: string]: {
    pages?: Record<string, string[]>
    activeCustomer?: {
      id?: string
      orders?: {
        totalItems: number
      }
    }
  }
}

export interface StoreState {
  [key: string]: any

  // activeChannel
  activeChannel?: ChannelFragment | undefined | null

  // activeCustomer
  activeCustomer?: CustomerFragment | undefined | null
  addresses?: AddressFragment[] | undefined | null

  // activeOrder
  activeOrder?: OrderFragment | undefined | null

  // order (guest checkout)
  activeCustomerOrders?: ActiveCustomerOrdersState
  ordersItems?: Record<string, OrderFragment | undefined | null>
  order?: Record<string, OrderFragment | undefined | null>

  // availableCountries
  availableCountries?: CountryFragment[] | undefined | null

  // eligibleShippingMethods
  eligibleShippingMethods?: ShippingMethodQuoteFragment[] | undefined | null

  // eligiblePaymentMethods
  eligiblePaymentMethods?: PaymentMethodQuoteFragment[] | undefined | null

  // collections
  collections?: CollectionListItemFragment[] | undefined | null
  collection?: Record<string, CollectionDetailFragment | undefined | null>

  // product feed data
  search?: SearchState
  items?: Record<string, SearchResultFragment | undefined | null>

  product?: Record<string, ProductFragment | undefined | null>
  facetValues?: FacetValueFragment[] | undefined | null
  facetValue?: Record<string, FacetValueFragment | undefined | null>

  // stripe
  stripePaymentIntent?: string | null

  // observed
  observed?: SearchResultFragment[]
}

export function useStore() {
  return useState<StoreState>('store', () => ({
    headerHeight: 0,
  }))
}
