import destr from 'destr'
import type { ComputedRef, Ref } from 'vue'
import { ErrorCode } from '#graphql-operations'
import type { AsyncDataExecuteOptions } from '#app/composables/asyncData'
import type {
  CreateAddressInput,
  CreateCustomerInput,
  ErrorResultFragment,
  UpdateOrderCustomFieldsInput as IUpdateOrderCustomFieldsInput,
  UpdateOrderInput as IUpdateOrderInput,
  InputMaybe,
  OrderFragment,
  OrderLineFragment,
  PaymentInput,
  PaymentMethodQuoteFragment,
  ProductVariantFragment,
  ShippingMethodQuoteFragment,
} from '#graphql-operations'
import type { PaymentMethod } from '~/composables/paymentMethod'
import {
  CouponCodeExpiredError,
  CouponCodeInvalidError,
  CouponCodeLimitError,
  IneligibleShippingMethodError,
  NoActiveOrderError,
  OrderModificationError,
} from '~/constants/errors'
import { useToast } from '~/components/ui/toast'

/** @see https://github.com/exaltedstore/exalted-backend/blob/develop/src/utils/order.ts */
export interface ParcelPoint {
  code: string
  name: string
  street: string
  city: string
  zipCode: string
  country: string
  lat: string | number
  lng: string | number
  //
  courier: string // custom, should match balikobot
}

export interface UpdateOrderCustomFieldsInput extends Omit<IUpdateOrderCustomFieldsInput, 'parcelPoint'> {
  parcelPoint?: InputMaybe<ParcelPoint>
}

export interface UpdateOrderInput extends Omit<IUpdateOrderInput, 'customFields'> {
  customFields?: InputMaybe<UpdateOrderCustomFieldsInput>
}

interface CustomOrderStates {}
export type OrderState = | 'Created' | 'Draft' | 'AddingItems' | 'ArrangingPayment' | 'Validated' | 'PaymentAuthorized' | 'PaymentSettled' | 'PartiallyShipped' | 'Shipped' | 'PartiallyDelivered' | 'Delivered' | 'Modifying' | 'ArrangingAdditionalPayment' | 'Cancelled' | keyof CustomOrderStates

export default defineNuxtPlugin(async (nuxtApp) => {
  if (nuxtApp.payload.error)
    return {}

  const { $localePath } = useNuxtApp()
  const activeOrderError = useState<ErrorResultFragment>('activeOrderError')
  const { data: activeOrder, pending: activeOrderPending, refresh: refreshActiveOrder } = await useAsyncData(
    'activeOrder',
    async () => (await useGraphqlQuery('activeOrder')).data.activeOrder,
    {
      lazy: true,
      server: false,
    },
  )

  const { data: shippingMethods, refresh: refreshShippingMethods }
    = await useAsyncData(
      'eligibleShippingMethods',
      async () => {
        const shippingMethods = await useGraphqlQuery(
          'eligibleShippingMethods',
        ).then(result => result.data.eligibleShippingMethods)
        return shippingMethods
        // await when(() => !isUpdatingCustomer.value && !isUpdatingShippingAddress.value)
        // isUpdatingShippingMethod.value = true

        // const shippingMethods = await useGraphqlQuery('eligibleShippingMethods').then(result => result.data.eligibleShippingMethods)

        // if (shippingMethods.length !== 1)
        //   console.warn(`There are ${shippingMethods.length} shipping methods, but only one is expected`)

        // if (
        //   !shippingMethod.value
        //   // Should never happen, as there should be only one shipping method, and it should be selected
        //   || (shippingMethod.value?.id && activeOrder.value?.shippingLines[0]?.shippingMethod.id !== shippingMethod.value?.id)
        // ) {
        //   const defaultShippingMethodCode = useRuntimeConfig().public.defaultShippingMethodCode
        //   let defaultShippingMethod = shippingMethods.find(method => method.code === defaultShippingMethodCode)

        //   if (defaultShippingMethod || shippingMethods.length === 1) {
        //     defaultShippingMethod = defaultShippingMethod || shippingMethods[0]
        //     isShippingMethodLoading.value = true
        //     await setOrderShippingMethod(defaultShippingMethod)
        //     shippingMethod.value = {
        //       id: defaultShippingMethod.id,
        //       service_id: ''
        //     }
        //     isShippingMethodLoading.value = false
        //   }
        //   else {
        //     console.warn(`Default shipping method with code "${defaultShippingMethodCode}" not found`)
        //   }
        // }

        // isUpdatingShippingMethod.value = false

        // return shippingMethods
      },
      { lazy: true, server: false },
    )

  const hasActiveOrder = computed(() => activeOrder.value && activeOrder.value.active && activeOrder.value.lines.length > 0)
  const cartDrawerOpen = useState<boolean>('cartDrawerOpen')
  const { toast } = useToast()
  const { t } = useNuxtApp().$i18n

  addRouteMiddleware('checkout', (to, from) => {
    if (to.meta.checkout && !hasActiveOrder.value) {
      if (import.meta.server && to.path === from.path)
        return navigateTo($localePath('/'))
      return navigateTo($localePath('/'))
    }
  }, { global: true })

  const currentRoute = useRoute()

  if (import.meta.client) {
    watch(hasActiveOrder, async (hasActiveOrder) => {
      if (currentRoute.meta.checkout && !hasActiveOrder)
        await navigateTo($localePath('/'))
    })
  }

  // Order State
  const transitionOrderToState = async (nextState: OrderState) => {
    if (activeOrder.value?.state === nextState || !hasActiveOrder.value)
      return

    activeOrderPending.value = true
    const nextOrderStates = (await useGraphqlQuery('nextOrderStates')).data.nextOrderStates
    activeOrderPending.value = false

    if (nextOrderStates.includes(nextState)) {
      const result = (await useGraphqlMutation('transitionOrderToState', { state: nextState })).data.transitionOrderToState

      if (isGraphqlError(result)) {
        if (nextState === 'Validated') {
          toast({
            variant: 'destructive',
            title: t('validation.title'),
            description: t(result.transitionError),
          })
        }

        activeOrderError.value = result
        throw new Error(result.message)
      }

      if (isGraphqlType(result, 'Order')) {
        activeOrder.value = result
        return
      }

      throw new Error('Failed to transition order to state')
    }
  }

  // Cart
  const addItemToOrder = async (productVariant?: ProductVariantFragment, quantity = 1) => {
    if (productVariant && quantity) {
      activeOrderPending.value = true
      await transitionOrderToState('AddingItems')
      const result = (await useGraphqlMutation('addItemToOrder', { productVariantId: productVariant.id, quantity: +quantity })).data.addItemToOrder
      activeOrderPending.value = false

      if (isGraphqlError(result)) {
        activeOrderError.value = result
        throw new Error(result.message)
      }

      if (isGraphqlType(result, 'Order')) {
        activeOrder.value = result
        return
      }

      throw new Error('Failed to add item to order')
    }
  }

  const removeOrderLine = async (line: OrderLineFragment) => {
    activeOrderPending.value = true
    await transitionOrderToState('AddingItems')
    const result = (await useGraphqlMutation('removeOrderLine', { orderLineId: line.id })).data.removeOrderLine
    activeOrderPending.value = false

    if (isGraphqlError(result)) {
      activeOrderError.value = result
      throw new Error(result.message)
    }

    if (isGraphqlType(result, 'Order')) {
      activeOrder.value = result
      return
    }

    throw new Error('Failed to remove item from order')
  }

  const adjustOrderLine = async (line: OrderLineFragment, quantity: number) => {
    activeOrderPending.value = true
    await transitionOrderToState('AddingItems')
    const result = (await useGraphqlMutation('adjustOrderLine', { orderLineId: line.id, quantity })).data.adjustOrderLine
    await refreshShippingMethods()
    activeOrderPending.value = false

    if (isGraphqlError(result)) {
      activeOrderError.value = result
      throw new Error(result.message)
    }

    if (isGraphqlType(result, 'Order')) {
      activeOrder.value = result
      return
    }

    throw new Error('Failed to adjust order line')
  }

  // Customer
  const setCustomerForOrder = async (customer: CreateCustomerInput) => {
    activeOrderPending.value = true
    await transitionOrderToState('AddingItems')
    const result = (await useGraphqlMutation('setCustomerForOrder', { input: customer })).data.setCustomerForOrder
    activeOrderPending.value = false

    if (isGraphqlError(result)) {
      activeOrderError.value = result
      throw new Error(result.message)
    }

    if (isGraphqlType(result, 'Order')) {
      activeOrder.value = result
      return
    }

    throw new Error('Failed to set customer for order')
  }

  // Shipping
  const setOrderShippingMethod = async (shippingMethod: Omit<ShippingMethodQuoteFragment, 'metadata' | 'price'>) => {
    console.log('HERE SHIPPING')
    activeOrderPending.value = true
    await transitionOrderToState('AddingItems')
    const { data } = await useGraphqlMutation('setOrderShippingMethod', { shippingMethodId: shippingMethod.id })
    activeOrderPending.value = false

    if (isGraphqlError(data.setOrderShippingMethod)) {
      switch (data.setOrderShippingMethod.errorCode) {
        case (ErrorCode.INELIGIBLE_SHIPPING_METHOD_ERROR): {
          throw new IneligibleShippingMethodError(data.setOrderShippingMethod.message)
        }
        case ErrorCode.NO_ACTIVE_ORDER_ERROR: {
          throw new NoActiveOrderError(data.setOrderShippingMethod.message)
        }
        case ErrorCode.ORDER_MODIFICATION_ERROR: {
          throw new OrderModificationError(data.setOrderShippingMethod.message)
        }
        default: {
          throw new Error(data.setOrderShippingMethod.message)
        }
      }
    }

    if (isGraphqlType(data.setOrderShippingMethod, 'Order')) {
      activeOrder.value = data.setOrderShippingMethod
      return
    }

    throw new Error('Failed to set shipping method for order')
  }

  const setOrderShippingAddress = async (address: Partial<CreateAddressInput>, shouldValidate = true) => {
    activeOrderPending.value = true
    await transitionOrderToState('AddingItems')

    const result = (await useGraphqlMutation('setOrderShippingAddress', { input: assignBlankAddressFields(address) })).data.setOrderShippingAddress
    activeOrderPending.value = false

    if (isGraphqlError(result)) {
      activeOrderError.value = result
      throw new Error(result.message)
    }

    if (isGraphqlType(result, 'Order')) {
      activeOrder.value = result
      return
    }

    throw new Error('Failed to set shipping address for order')
  }

  const applyCouponCode = async (couponCode: string) => {
    activeOrderPending.value = true
    await transitionOrderToState('AddingItems')
    const { data } = await useGraphqlMutation('applyCouponCode', { couponCode })
    activeOrderPending.value = false

    if (isGraphQLError(data.applyCouponCode)) {
      switch (data.applyCouponCode.errorCode) {
        case ErrorCode.COUPON_CODE_EXPIRED_ERROR: {
          throw new CouponCodeExpiredError(data.applyCouponCode.message)
        }
        case ErrorCode.COUPON_CODE_INVALID_ERROR: {
          throw new CouponCodeInvalidError(data.applyCouponCode.message)
        }
        case ErrorCode.COUPON_CODE_LIMIT_ERROR: {
          throw new CouponCodeLimitError(data.applyCouponCode.message)
        }
        default: {
          throw new Error(data.applyCouponCode.message)
        }
      }
    }

    if (isGraphqlType(data.applyCouponCode, 'Order')) {
      activeOrder.value = data.applyCouponCode
      return
    }

    throw new Error('Failed to apply coupon code to order')
  }

  const removeCouponCode = async (couponCode: string) => {
    activeOrderPending.value = true
    await transitionOrderToState('AddingItems')
    const { data } = await useGraphqlMutation('removeCouponCode', { couponCode })
    activeOrderPending.value = false

    if (isGraphQLError(data.removeCouponCode)) {
      throw new Error(data.removeCouponCode.message)
    }

    if (isGraphqlType(data.removeCouponCode, 'Order')) {
      activeOrder.value = data.removeCouponCode
      return
    }

    throw new Error('Failed to remove coupon code from order')
  }

  const addPaymentToOrder = async (input: PaymentInput) => {
    activeOrderPending.value = true
    await transitionOrderToState('ArrangingPayment')
    const result = (await useGraphqlMutation('addPaymentToOrder', { input })).data.addPaymentToOrder
    activeOrderPending.value = false

    if (isGraphqlError(result)) {
      activeOrderError.value = result
      throw new Error(result.message)
    }

    if (isGraphqlType(result, 'Order')) {
      // activeOrder.value = result
      return
    }

    throw new Error('Failed to add payment to order')
  }

  const fetchEligiblePaymentMethods = async () => {
    const { data: { eligiblePaymentMethods } } = await useGraphqlQuery('eligiblePaymentMethods') as { data: { eligiblePaymentMethods: PaymentMethodQuoteFragment[] } }

    return eligiblePaymentMethods.filter(method => method.isEligible)?.map((method) => {
      let icon = 'i-heroicons:credit-card-solid'
      let logo, external

      if (method.code?.includes('bank'))
        icon = 'i-heroicons:building-library-solid'

      if (method.code?.includes('comgate')) {
        logo = 'payments/blik'
        external = true
      }

      const props = method.code?.includes('stripe') ? { stripe: 'card' } : {}

      return { ...method, icon, logo, external, ...props } as PaymentMethod
    })
  }

  const setOrderCustomFields = async (input: UpdateOrderInput) => {
    activeOrderPending.value = true

    if (!input?.customFields)
      return

    const customFields = Object.entries(input.customFields).reduce((result, [key, value]) => {
      if (result) {
        result[key as keyof UpdateOrderCustomFieldsInput] = typeof value === 'object' && value !== null ? JSON.stringify(value) : value
      }
      return result
    }, {} as IUpdateOrderInput['customFields'])

    const result = (
      await useGraphqlMutation('setOrderCustomFields', { input: { customFields } })
    ).data.setOrderCustomFields
    activeOrderPending.value = false

    if (isGraphqlError(result)) {
      activeOrderError.value = result
      throw new Error(result.message)
    }

    if (isGraphqlType(result, 'Order')) {
      activeOrder.value = result
      return
    }

    throw new Error('Failed to set custom fields for order')
  }

  const orderTotal = computed(() => {
    return activeOrder.value?.total || 0
  })

  const orderTotalQuantity = computed(() => activeOrder.value?.totalQuantity || 0)

  const parcelPoint = computed(() => {
    if (activeOrder.value?.customFields?.parcelPoint)
      return destr<ParcelPoint>(activeOrder.value.customFields.parcelPoint)
    return null
  })

  return {
    provide: {
      activeOrder: {
        hasActiveOrder,
        cartDrawerOpen,
        activeOrder,
        activeOrderPending,
        shippingMethods,
        transitionOrderToState,
        refreshActiveOrder,
        addItemToOrder,
        removeOrderLine,
        adjustOrderLine,
        setCustomerForOrder,
        setOrderShippingMethod,
        setOrderShippingAddress,
        addPaymentToOrder,
        applyCouponCode,
        removeCouponCode,
        fetchEligiblePaymentMethods,
        setOrderCustomFields,
        orderTotal,
        orderTotalQuantity,
        parcelPoint,
      },
    },
  }
})

interface ActiveOrderPlugin {
  hasHasActiveOrder: ComputedRef<boolean>
  cartDrawerOpen: Ref<boolean>
  activeOrder: Ref<OrderFragment>
  activeOrderPending: Ref<boolean>
  transitionOrderToState: (nextState: OrderState) => Promise<void>
  refreshActiveOrder: (opts?: AsyncDataExecuteOptions) => Promise<void>
  addItemToOrder: (productVariant: ProductVariantFragment, quantity?: number) => Promise<void>
  removeOrderLine: (line: OrderLineFragment) => Promise<void>
  adjustOrderLine: (line: OrderLineFragment, quantity: number) => Promise<void>
  setCustomerForOrder: (customer: CreateCustomerInput) => Promise<void>
  setOrderShippingMethod: (shippingMethod: Omit<ShippingMethodQuoteFragment, 'metadata' | 'price'>) => Promise<void>
  setOrderShippingAddress: (address: Partial<CreateAddressInput>, shouldValidate?: boolean) => Promise<void>
  addPaymentToOrder: (input: PaymentInput) => Promise<void>
  applyCouponCode: (couponCode: string) => Promise<void>
  removeCouponCode: (couponCode: string) => Promise<void>
  fetchEligiblePaymentMethods: () => Promise<PaymentMethod[]>
  setOrderCustomFields: (input: UpdateOrderInput) => Promise<void>
  orderTotal: ComputedRef<number>
  orderTotalQuantity: ComputedRef<number>
  parcelPoint: ComputedRef<ParcelPoint | null>
}

declare module '#app' {
  interface NuxtApp {
    $activeOrder: ActiveOrderPlugin
  }
  interface PageMeta {
    checkout?: boolean
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $activeOrder: ActiveOrderPlugin
  }
}
