
// @ts-nocheck


export const localeCodes =  [
  "en",
  "cs",
  "sk",
  "pl",
  "hu",
  "ro"
]

export const localeLoaders = {
  "en": [{ key: "../locales/en.json", load: () => import("../locales/en.json" /* webpackChunkName: "locale__usr_local_app_locales_en_json" */), cache: true }],
  "cs": [{ key: "../locales/cs.json", load: () => import("../locales/cs.json" /* webpackChunkName: "locale__usr_local_app_locales_cs_json" */), cache: true }],
  "sk": [{ key: "../locales/sk.json", load: () => import("../locales/sk.json" /* webpackChunkName: "locale__usr_local_app_locales_sk_json" */), cache: true }],
  "pl": [{ key: "../locales/pl.json", load: () => import("../locales/pl.json" /* webpackChunkName: "locale__usr_local_app_locales_pl_json" */), cache: true }],
  "hu": [{ key: "../locales/hu.json", load: () => import("../locales/hu.json" /* webpackChunkName: "locale__usr_local_app_locales_hu_json" */), cache: true }],
  "ro": [{ key: "../locales/ro.json", load: () => import("../locales/ro.json" /* webpackChunkName: "locale__usr_local_app_locales_ro_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "language": "en-US",
      "name": "English",
      "nativeName": "English",
      "dir": "ltr",
      "country": "US",
      "currency": "USD",
      "files": [
        "/usr/local/app/locales/en.json"
      ]
    },
    {
      "code": "cs",
      "language": "cs-CZ",
      "name": "Czech",
      "nativeName": "Čeština",
      "dir": "ltr",
      "country": "CZ",
      "currency": "CZK",
      "files": [
        "/usr/local/app/locales/cs.json"
      ]
    },
    {
      "code": "sk",
      "language": "sk-SK",
      "name": "Slovak",
      "nativeName": "Slovenčina",
      "dir": "ltr",
      "country": "SK",
      "currency": "EUR",
      "files": [
        "/usr/local/app/locales/sk.json"
      ]
    },
    {
      "code": "pl",
      "language": "pl-PL",
      "name": "Polish",
      "nativeName": "Polski",
      "dir": "ltr",
      "country": "PL",
      "currency": "PLN",
      "files": [
        "/usr/local/app/locales/pl.json"
      ]
    },
    {
      "code": "hu",
      "language": "hu-HU",
      "name": "Hungarian",
      "nativeName": "Magyar",
      "dir": "ltr",
      "country": "HU",
      "currency": "HUF",
      "files": [
        "/usr/local/app/locales/hu.json"
      ]
    },
    {
      "code": "ro",
      "language": "ro-RO",
      "name": "Romanian",
      "nativeName": "Română",
      "dir": "ltr",
      "country": "RO",
      "currency": "RON",
      "files": [
        "/usr/local/app/locales/ro.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "locale",
    "cookieSecure": false,
    "fallbackLocale": "en",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "language": "en-US",
    "name": "English",
    "nativeName": "English",
    "dir": "ltr",
    "country": "US",
    "currency": "USD",
    "files": [
      {
        "path": "/usr/local/app/locales/en.json"
      }
    ]
  },
  {
    "code": "cs",
    "language": "cs-CZ",
    "name": "Czech",
    "nativeName": "Čeština",
    "dir": "ltr",
    "country": "CZ",
    "currency": "CZK",
    "files": [
      {
        "path": "/usr/local/app/locales/cs.json"
      }
    ]
  },
  {
    "code": "sk",
    "language": "sk-SK",
    "name": "Slovak",
    "nativeName": "Slovenčina",
    "dir": "ltr",
    "country": "SK",
    "currency": "EUR",
    "files": [
      {
        "path": "/usr/local/app/locales/sk.json"
      }
    ]
  },
  {
    "code": "pl",
    "language": "pl-PL",
    "name": "Polish",
    "nativeName": "Polski",
    "dir": "ltr",
    "country": "PL",
    "currency": "PLN",
    "files": [
      {
        "path": "/usr/local/app/locales/pl.json"
      }
    ]
  },
  {
    "code": "hu",
    "language": "hu-HU",
    "name": "Hungarian",
    "nativeName": "Magyar",
    "dir": "ltr",
    "country": "HU",
    "currency": "HUF",
    "files": [
      {
        "path": "/usr/local/app/locales/hu.json"
      }
    ]
  },
  {
    "code": "ro",
    "language": "ro-RO",
    "name": "Romanian",
    "nativeName": "Română",
    "dir": "ltr",
    "country": "RO",
    "currency": "RON",
    "files": [
      {
        "path": "/usr/local/app/locales/ro.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
