import type { ComputedRef, Ref } from 'vue'
import type { CollectionListItemFragment, CollectionListOptions, InputMaybe } from '#graphql-operations'
import { LIST_QUERY_LIMIT, ROOT_COLLECTION } from '~/constants'
import { type CollectionListItemFragmentWithChildren, buildTree } from '~/composables/collections'

export default defineNuxtPlugin(async (nuxtApp) => {
  // Skip plugin when rendering error page
  if (nuxtApp.payload.error)
    return {}

  const fetchCollections = (options?: InputMaybe<CollectionListOptions>) => useGraphqlQuery('collections', { options })

  const collections = useState<Record<string, CollectionListItemFragment> | null>('collections')
  const rootCollections = computed(() => Object.values(collections.value ?? {}).filter(collection => collection.parent?.name === ROOT_COLLECTION).sort((a, b) => a.position - b.position))
  const collectionList = computed(() => Object.values(collections.value ?? {}).sort((a, b) => a.position - b.position))
  const collectionTree = computed(() => buildTree(collectionList.value))

  await useAsyncData('topLevelCollections', () => fetchCollections({
    take: LIST_QUERY_LIMIT,
    topLevelOnly: true,
  }).then((result) => {
    const items: CollectionListItemFragment[] = result.data.collections.items
    // convert to map of id -> collection
    const map = Object.fromEntries(items.map(item => [item.slug, item]))
    collections.value = Object.assign({}, collections.value, map)
    return items
  }), { server: true, lazy: true })

  const fetchChildrenOfCollection = async (collectionId: string | string[]) => {
    const parentIds = Array.isArray(collectionId) ? collectionId : [collectionId]
    const existingSlugs = new Set(Object.keys(collections.value ?? {}))

    const fetchAndUpdateCollections = async (skip: number) => {
      const result = await fetchCollections({
        skip,
        take: LIST_QUERY_LIMIT,
        filter: { parentId: { in: parentIds } },
      })

      const newCollections: CollectionListItemFragment[] = result.data.collections.items.filter((collection: CollectionListItemFragment) => !existingSlugs.has(collection.slug))

      collections.value = {
        ...collections.value,
        ...Object.fromEntries(newCollections.map(item => [item.slug, item])),
      }

      newCollections.forEach(collection => existingSlugs.add(collection.slug))

      return result
    }

    const initialCollections = await fetchAndUpdateCollections(0)

    const totalItems = initialCollections.data.collections.totalItems

    const totalBatches = Math.ceil(totalItems / LIST_QUERY_LIMIT)

    // Fetch and update in further batches
    for (let batch = 1; batch < totalBatches; batch++) {
      const skip = batch * LIST_QUERY_LIMIT
      await fetchAndUpdateCollections(skip)
    }
  }

  function getFullSlugPath(collection: CollectionListItemFragmentWithChildren | null): string {
    const slugs = []
    let currentCollection: CollectionListItemFragmentWithChildren | CollectionListItemFragment | null = collection

    while (currentCollection && currentCollection.slug !== ROOT_COLLECTION) {
      slugs.unshift(currentCollection.slug)
      currentCollection = currentCollection.parent?.slug ? collections.value?.[currentCollection.parent.slug] || null : null
    }

    return slugs.join('/')
  }

  return {
    provide: {
      collections: {
        collections: readonly(collections),
        rootCollections,
        collectionTree,
        fetchChildrenOfCollection,
        getFullSlugPath,
      },
    },
  }
})

interface CollectionsPlugin {
  collections: Ref<Record<string, CollectionListItemFragment> | null>
  rootCollections: ComputedRef<CollectionListItemFragment[]>
  collectionTree: ComputedRef<ReturnType<typeof buildTree>>
  fetchChildrenOfCollection: (collectionId: string | string[]) => Promise<void>
  getFullSlugPath: (collection: CollectionListItemFragmentWithChildren) => string
}

declare module '#app' {
  interface NuxtApp {
    $collections: CollectionsPlugin
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $collections: CollectionsPlugin
  }
}
