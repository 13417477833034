import type { ComputedRef, Ref } from 'vue'
import type { SearchResultFragment } from '#graphql-operations'
import { AddToObservedError, RemoveFromObservedError } from '~/constants/errors'

export default defineNuxtPlugin({
  name: 'observed',
  async setup(_nuxtApp) {
    const state = useState<SearchResultFragment[]>('observed', () => [])
    const loggedIn = computed(() => useAuth()?.loggedIn.value)
    const loading = ref(false)

    const { refresh: refreshWishlist } = await useAsyncData('observed', async () => {
      if (!loggedIn?.value)
        return null

      try {
        loading.value = true
        const { data } = await useGraphqlQuery('search', { search: { wishlist: true } })
        state.value = data?.search?.items || []
      }
      finally {
        loading.value = false
      }

      return state.value
    }, {
      lazy: true,
      deep: false,
      immediate: !state.value.length,
      watch: [loggedIn],
    })

    const addToWishlist = async (productVariantID: string) => {
      try {
        loading.value = true
        const { data } = await useGraphqlMutation('addToObserved', { input: { productVariantID, wishlist: true } })
        if (data.addToObserved?.__typename === 'AddToObservedError') {
          throw new AddToObservedError(data.addToObserved.message)
        }
        await refreshWishlist()
      }
      finally {
        loading.value = false
      }
    }

    const removeFromObserved = async (productID: string) => {
      try {
        loading.value = true
        const { data } = await useGraphqlMutation('removeFromObserved', { input: { productID } })

        if (data.removeFromObserved?.__typename === 'RemoveFromObservedError') {
          throw new RemoveFromObservedError(data.removeFromObserved.message)
        }
        await refreshWishlist()
      }
      finally {
        loading.value = false
      }
    }

    const toggleWishlist = async (productVariantId: string, isObserved: boolean) => {
      if (isObserved) {
        await removeFromObserved(productVariantId)
      }
      else {
        await addToWishlist(productVariantId)
      }
    }

    const isProductVariantObserved = (productVariantId: string) => {
      return !!state.value?.find(el => el.productVariantId === productVariantId)
    }

    const wishlistedProductIds = computed(() => state.value.map(i => i.id))
    const isWishlisted = (productId: string) => wishlistedProductIds.value.includes(productId)

    const wishlistTotalQuantity = computed(() => state.value.length)

    return {
      provide: {
        observed: {
          items: readonly(state),
          wishlistTotalQuantity,
          loading: readonly(loading),
          isWishlisted,
          addToObserved: addToWishlist,
          addToWishlist,
          removeFromObserved,
          removeFromWishlist: removeFromObserved,
          toggleObserved: toggleWishlist,
          toggleWishlist,
          isProductVariantObserved,
        },
      },
    }
  },
})

interface ObservedPlugin {
  items: Readonly<Ref<SearchResultFragment[]>>
  wishlistTotalQuantity: ComputedRef<number>
  loading: Readonly<Ref<boolean>>
  isWishlisted: (productId: string) => boolean
  addToObserved: (productVariantID: string) => Promise<void>
  addToWishlist: (productVariantID: string) => Promise<void>
  removeFromObserved: (productID: string) => Promise<void>
  removeFromWishlist: (productID: string) => Promise<void>
  toggleObserved: (productVariantId: string, isObserved: boolean) => Promise<void>
  toggleWishlist: (productVariantId: string, isObserved: boolean) => Promise<void>
  isProductVariantObserved: (productVariantId: string) => boolean
}

declare module '#app' {
  interface NuxtApp {
    $observed: ObservedPlugin
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $observed: ObservedPlugin
  }
}
