import type { ComputedRef, Ref } from 'vue'
import type { ActiveCustomerQuery, MeQuery } from '#graphql-operations'

export default defineNuxtPlugin(async (nuxtApp) => {
  // Skip plugin when rendering error page
  if (nuxtApp.payload.error)
    return {}

  const { $localePath } = useNuxtApp()
  const { data: activeCustomer, refresh: updateSession } = await useAsyncData(
    'activeCustomer',
    () => useGraphqlQuery('activeCustomer').then(({ data }: { data: ActiveCustomerQuery }) => data.activeCustomer),
    { server: true, lazy: true },
  )

  const { data: influencer, refresh: refreshInfluencer } = await useAsyncData(
    'influencer',
    () => useGraphqlQuery('me').then(({ data }: { data: MeQuery }) => data.me?.influencer ?? null),
    { server: true, lazy: true },
  )

  const loggedIn = computed(() => activeCustomer.value != null)

  addRouteMiddleware('auth', (to, from) => {
    if (to.meta.auth && !loggedIn.value) {
      if (import.meta.server && to.path === from.path)
        return
      return `${$localePath('/auth/login')}?redirectTo=${to.path}`
    }
  }, { global: true })

  const currentRoute = useRoute()

  if (import.meta.client) {
    watch(loggedIn, async (loggedIn) => {
      if (currentRoute.meta.auth && !loggedIn)
        await navigateTo(`${$localePath('/auth/login')}?redirectTo=${currentRoute.path}`)
    })
  }

  if (loggedIn.value && ['/auth/login', $localePath('/auth/login')].includes(currentRoute.path)) {
    const to = (currentRoute.query.redirectTo || $localePath('/')).toString()
    await navigateTo(to)
  }

  return {
    provide: {
      auth: {
        loggedIn,
        session: activeCustomer,
        activeCustomer,
        updateSession,
        influencer,
        refreshInfluencer,
      },
    },
  }
})

interface AuthPlugin {
  loggedIn: ComputedRef<boolean>
  session: Ref<ActiveCustomerQuery['activeCustomer']>
  activeCustomer: Ref<ActiveCustomerQuery['activeCustomer']>
  updateSession: () => Promise<void>
  influencer: Ref<NonNullable<MeQuery['me']>['influencer']>
  refreshInfluencer: () => Promise<void>
}

declare module '#app' {
  interface NuxtApp {
    $auth: AuthPlugin
  }
  interface PageMeta {
    auth?: boolean
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $auth: AuthPlugin
  }
}
