import { ErrorCode } from '#graphql-operations'
import type {
  LoginMutationVariables,
  RegisterCustomerInput,
  RequestPasswordResetMutationVariables,
  ResetPasswordMutationVariables,
  VerifyCustomerAccountMutationVariables,
} from '#graphql-operations'
import {
  InvalidCredentialsError,
  MissingPasswordError,
  NativeAuthStrategyError,
  NotVerifiedError,
  PasswordValidationError,
} from '~/constants/errors'
import type { WithRequired } from '~/utils/types'

export const useAuth = () => useNuxtApp().$auth

export async function authLogin(body: LoginMutationVariables) {
  const localePath = useLocalePath()
  const { data } = await useGraphqlMutation('login', body)

  if (isGraphQLError(data.login)) {
    switch (data.login.errorCode) {
      case ErrorCode.INVALID_CREDENTIALS_ERROR: {
        throw new InvalidCredentialsError(data.login.message)
      }
      case ErrorCode.NATIVE_AUTH_STRATEGY_ERROR: {
        throw new NativeAuthStrategyError(data.registerCustomerAccount.message)
      }
      case ErrorCode.NOT_VERIFIED_ERROR: {
        throw new NotVerifiedError(data.login.message)
      }
      default: {
        throw new Error(data.login.message)
      }
    }
  }

  const { redirectTo } = useRoute().query
  await useAuth().updateSession()
  await navigateTo(redirectTo as string || localePath('/'))
}

export async function authVerifyEmail(body: VerifyCustomerAccountMutationVariables) {
  const localePath = useLocalePath()
  // NOTE: This acts as authRegister, because email verification is required
  await useGraphqlMutation('verifyCustomerAccount', body)
  await useAuth().updateSession()
  const { redirectTo } = useRoute().query
  await navigateTo(redirectTo as string || localePath('/'))
}

export async function authRegister(body: WithRequired<RegisterCustomerInput, 'password'>) {
  const { data } = await useGraphqlMutation('registerCustomerAccount', { input: body })

  if (isGraphQLError(data.registerCustomerAccount)) {
    switch (data.registerCustomerAccount.errorCode) {
      case ErrorCode.MISSING_PASSWORD_ERROR: {
        throw new MissingPasswordError(data.registerCustomerAccount.message)
      }
      case ErrorCode.NATIVE_AUTH_STRATEGY_ERROR: {
        throw new NativeAuthStrategyError(data.registerCustomerAccount.message)
      }
      case ErrorCode.PASSWORD_VALIDATION_ERROR: {
        throw new PasswordValidationError(data.registerCustomerAccount.message)
      }
      default: {
        throw new Error(data.registerCustomerAccount.message)
      }
    }
  }
}

export async function authLogout() {
  const store = useStore()
  await useGraphqlMutation('logout')
  store.value.activeCustomer = store.value.activeOrder = null
  await useAuth().updateSession()
}

export async function authForgotPassword(body: RequestPasswordResetMutationVariables) {
  await useGraphqlMutation('requestPasswordReset', body)
}

export async function authResetPassword(body: ResetPasswordMutationVariables) {
  const localePath = useLocalePath()
  await useGraphqlMutation('resetPassword', body)
  const { redirectTo } = useRoute().query
  await useAuth().updateSession()
  await navigateTo(redirectTo as string || localePath('/'))
}
