import type { CollectionListItemFragment } from '#graphql-operations'
import { ROOT_COLLECTION } from '~/constants'

export type CollectionListItemFragmentWithChildren = CollectionListItemFragment & { children: { [id: string]: CollectionListItemFragmentWithChildren } }

export function buildTree(collections?: CollectionListItemFragment[] | null) {
  if (collections == null)
    return {}

  const lookup: { [key: string]: CollectionListItemFragmentWithChildren } = {}
  const rootNodes: { [slug: string]: CollectionListItemFragmentWithChildren } = {}

  for (const collection of collections)
    lookup[collection.slug] = { ...collection, children: {} }

  for (const collection of collections) {
    if (collection.parent && collection.parent.name === ROOT_COLLECTION) {
      rootNodes[collection.slug] = lookup[collection.slug]
    }
    else if (collection.parent && collection.parent.slug in lookup) {
      if (collection.slug !== collection.parent.slug)
        lookup[collection.parent.slug].children[collection.slug] = lookup[collection.slug]
      else
        console.error(`Circular reference detected in collection with slug ${collection.slug}`)
    }
  }

  return rootNodes
}

export function useCollections() {
  return useNuxtApp().$collections
}

export function useMainCollections() {
  const route = useRoute()
  const { rootCollections, collectionTree } = useCollections()

  const mainCollections = computed(() => rootCollections.value.sort(({ position: a }, { position: b }) => a - b).slice(0, 2))

  const activeMainCollectionSlug = useState<string>()

  const getSlugFromRoute = () => {
    if (route.name?.toString().startsWith('collections-slug') && route.params.slug) {
      return Array.isArray(route.params.slug)
        ? route.params.slug[0]
        : route.params.slug.split('/')[0]
    }
    return null
  }

  watch([route, mainCollections], ([_, newCollections]) => {
    const routeSlug = getSlugFromRoute()
    if (routeSlug && mainCollections.value.some(c => c.slug === routeSlug)) {
      activeMainCollectionSlug.value = routeSlug
    }
    else if (!activeMainCollectionSlug.value && newCollections.length > 0) {
      activeMainCollectionSlug.value = newCollections[0].slug
    }
  }, { immediate: true })

  // Function to change the active collection
  const setActiveCollection = (slug: string) => {
    activeMainCollectionSlug.value = slug
  }

  const mainCollectionChildren = computed(() => activeMainCollectionSlug.value ? collectionTree.value[activeMainCollectionSlug.value]?.children ?? {} : {})

  return {
    mainCollections,
    activeMainCollectionSlug: readonly(activeMainCollectionSlug),
    mainCollectionChildren,
    setActiveCollection,
  }
}
