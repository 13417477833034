import type { ButtonVariants } from '~/components/ui/button'

export const IMAGE_OPTION_GROUP_CODES = ['color']
export const LIST_QUERY_LIMIT = 100
export const ROOT_COLLECTION = '__root_collection__'
export const IN_STOCK = 'IN_STOCK'

export const config = {
  carousel: {
    wrapper: 'relative',
    container: 'relative w-full flex overflow-x-auto snap-x snap-mandatory scroll-smooth',
    item: 'flex flex-none snap-center',
    arrows: {
      wrapper: 'flex items-center justify-between',
    },
    indicators: {
      wrapper: 'absolute flex items-center justify-center gap-3 bottom-4 inset-x-0',
      base: 'rounded-full h-3 w-3',
      active: 'bg-primary-500 dark:bg-primary-400',
      inactive: 'bg-gray-100 dark:bg-gray-800',
    },
    default: {
      prevButton: {
        variant: 'default' as ButtonVariants['variant'],
        size: 'icon' as ButtonVariants['size'],
        class: 'rtl:[&_span:first-child]:rotate-180 absolute left-4 top-1/2 transform -translate-y-1/2 rounded-full',
      },
      prevButtonIcon: 'ph:caret-left-bold',
      nextButton: {
        variant: 'default' as ButtonVariants['variant'],
        size: 'icon' as ButtonVariants['size'],
        class: 'rtl:[&_span:last-child]:rotate-180 absolute right-4 top-1/2 transform -translate-y-1/2 rounded-full',
      },
      nextButtonIcon: 'ph:caret-right-bold',
    },
  },
}
