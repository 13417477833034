import { useStorage } from '@vueuse/core'
import type { Ref } from 'vue'
import type { LocaleObject } from '@nuxtjs/i18n'
import type { SearchResultFragment } from '#graphql-operations'

export default defineNuxtPlugin({
  name: 'product-history',
  setup(nuxtApp) {
    const locale = (nuxtApp.$i18n as any).locale as Ref<string>
    const locales = (nuxtApp.$i18n as any).locales as Ref<LocaleObject[]>
    const history = useStorage<(SearchResultFragment & { ts: number })[]>(`product-history:${locale.value}`, [])

    const checkProductFreshness = (localeCode: string) => {
      const localeStorage = useStorage<(SearchResultFragment & { ts: number })[]>(`product-history:${localeCode}`, [])
      const now = Date.now()
      localeStorage.value = localeStorage.value.filter(item => item?.ts ? now - item.ts < 7 * 24 * 60 * 60 * 1000 : true)
    }

    locales.value.forEach((localeObj) => {
      setInterval(() => checkProductFreshness(localeObj.code), 60 * 1000)
    })

    checkProductFreshness(locale.value)

    const addItemToHistory = (item: SearchResultFragment & { ts: number }) => {
      item.ts = Date.now()
      const index = history.value.findIndex(i => i.id === item.id)
      if (!~index) {
        history.value.unshift(item)
        history.value = history.value.slice(0, 12)
      }
      else {
        history.value.splice(index, 1)
        history.value.unshift(item)
      }
    }

    const clearProductHistory = () => {
      history.value = []
    }

    return {
      provide: {
        history: {
          history,
          addItemToHistory,
          clearProductHistory,
        },
      },
    }
  },
})

interface HistoryPlugin {
  history: Ref<(SearchResultFragment & { ts: number })[]>
  addItemToHistory: (item: SearchResultFragment & { ts: number }) => void
  clearProductHistory: () => void
}

declare module '#app' {
  interface NuxtApp {
    $history: HistoryPlugin
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $history: HistoryPlugin
  }
}
